import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import NewsCard from "components/NewsCard";
import Divider from "components/Divider";

import { usePosts } from "context/posts";

const Content = ({ pageData }) => {
  const { posts, categories } = usePosts();
  const slug = pageData?.slug;

  const [query, setQuery] = useState("");

  const formSubmit = (e) => {
    e.preventDefault();
    navigate(`/wyszukiwarka/?query=${query}`);
  };

  return (
    <section className="blog-main">
      <div className="container">
        <div className="blog-main__categories">
          <div className="blog-main__cat-text">Pokaż artykuły w kategorii:</div>
          <div className="blog-main__categories-container">
            <Link to="/blog/">Wszystkie</Link>
            {categories
              ?.filter((item) => item.slug !== "artykuly-partnerow")
              .map((item, index) => (
                <Link
                  to={`/blog/${item.slug}/`}
                  key={index}
                  className={`${item.slug}${
                    item.slug === slug ? " current" : ""
                  }`}
                >
                  {item.name}
                </Link>
              ))}
            <Link to="/blog/artykuly-partnerow/" className="artykuly-partnerow">
              Artykuły partnerów
            </Link>
            <Link to="/quizy/" className="quizy">
              Darmowe Testy i Quizy
            </Link>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-9">
            <form className="blog-main__search" onSubmit={formSubmit}>
              <div className="row align-items-center">
                <div className="col-md">
                  <p>Wpisz szukaną frazę:</p>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    name="search"
                    onChange={(e) => setQuery(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md">
                  <button type="submit" className="button button--green">
                    Szukaj
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Divider marginTop={45} marginBottom={45} />

        <div className="blog-main__posts">
          {posts
            ?.filter((item) =>
              item?.categories?.nodes?.some(
                (category) => category?.slug === slug
              )
            )
            .map((item, index) => (
              <NewsCard
                key={index}
                title={item.title}
                excerpt={item.excerpt}
                date={item.date}
                update={item?.acfPost?.postUpdateDate}
                url={item.uri}
                thumbnail={item.featuredImage?.node.sourceUrl}
                authorAvatar={item.author.node.avatar.url}
                authorName={`${item.author.node.firstName} ${item.author.node.lastName}`}
                categories={item.categories.nodes}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Content;
